<template>
  <div
    class="bleach-site active-banner"
    :class="{
      'show-banner': showBanner && !disableBanner,
      'cart-alert-active': alertActive
    }"
    v-if="GET_IS_CORE_CONTENT_READY"
  >
    <Navigation />
    <Banner v-if="getBanner" :content="getBanner" />
    <AuthPushdown />
    <div class="bleach-site__store" id="scroll-lock-content">
      <!-- <transition mode="in-out" name="fade"> -->
      <keep-alive
        :include="[
          'CollectionPage',
          'GenericProductListing',
          'Page',
          'ProductPage'
        ]"
        :max="3"
      >
        <router-view />
      </keep-alive>
      <!-- </transition> -->
    </div>
    <Footer v-if="getShouldHideLoader" :content="GET_SITE_CONFIG.footer" />
    <vue-accessible-modal />
    <modal-container />
  </div>
</template>

<script>
import ScrollLock from "@/helpers/ScrollLock.js"

import Navigation from "./modules/Navigation/Navigation"
import Banner from "./modules/Banner/Banner"
import Footer from "./modules/Footer/Footer"
import AuthPushdown from "./modules/Auth/AuthPushdown.vue"
import { mapState, mapMutations, mapGetters, mapActions } from "vuex"
import BleachAPI from "@/integration/BleachAPI"
import ExternalLoader from "@/integration/ExternalLoader"
import StoreLocaleModal from "@/modules/Modals/StoreLocaleModal"
import NewsletterModal from "@/modules/Modals/NewsletterModal"
import DiscountModal from "@/modules/Modals/DiscountModal"

export default {
  data: () => ({
    isInit: false,
    preloadTimer: null,
    isPreloadTimeout: false
  }),

  components: {
    AuthPushdown,
    Banner,
    Navigation,
    Footer
  },

  computed: {
    ...mapState("identity", ["authJWT", "buuid"]),
    ...mapState("scroll", ["showBanner", "disableBanner"]),
    ...mapState("locale", [
      "showLocaleSuggestionModal",
      "suggestedLocale",
      "suggestedShop",
      "locale",
      "shopName",
      "queueGeoFetch"
    ]),
    ...mapState("ui", ["modalQueueEnabled", "routeTransitionState"]),
    ...mapState("cart", ["alertActive"]),
    ...mapState("content", [
      "hasFullContent",
      "isCurrentPageContentLoaded",
      "currentPageImageManifest"
    ]),
    ...mapState("dieselgate", ["pageLoadIdle"]),
    ...mapGetters("content", [
      "GET_IS_CORE_CONTENT_READY",
      "GET_VIEWPORT_IMAGES_ARE_LOADED",
      "GET_SITE_CONFIG"
    ]),
    ...mapGetters("account", ["GET_SHOW_NEWSLETTER"]),
    ...mapGetters("queryParams", [
      "getHasDiscountQuery" /* ,
      "getHasValidatedDiscountQuery" */
    ]),

    getBanner() {
      return this.GET_SITE_CONFIG.siteBanner
    },

    getShouldHideLoader() {
      return (
        this.isPreloadTimeout ||
        (!this.routeTransitionState &&
          this
            .isCurrentPageContentLoaded) /*  &&
          this.GET_VIEWPORT_IMAGES_ARE_LOADED */
      )
    }
  },

  methods: {
    ...mapMutations("scroll", ["SET_SCROLL_POS"]),
    // ...mapActions("cart", ["VALIDATE_CART"]),
    ...mapActions("identity", ["currentAuthenticatedUser"]),
    ...mapActions("queryParams", ["PERSIST_PARAMS"]),
    // ...mapMutations("content", ["SET_CACHE_BUILDER_MODE"]),
    ...mapMutations("account", ["SET_MARKETING_DECLINED"]),

    onResize() {
      let width = window.innerWidth
      if (width != this.$store.state.breakpoints.CURRENT_WIDTH) {
        // Update the stored window width for next time
        this.$store.dispatch("breakpoints/UPDATE_CURRENT_WIDTH", width)
      }
    },

    onScroll() {
      this.SET_SCROLL_POS(document.scrollingElement.scrollTop)
    },

    initInputListeners() {
      // Let the document know when the mouse is being used
      document.body.addEventListener("mousedown", function() {
        document.body.classList.add("mouse-nav")
      })

      // Re-enable focus styling when Tab is pressed
      document.body.addEventListener("keydown", function(event) {
        if (event.keyCode === 9) {
          document.body.classList.remove("mouse-nav")
        }
      })
    },

    hideLoadingScreen() {
      const loadingElement = document.querySelector(".preload-loading-screen")
      this.debug.log("hideLoadingScreen", loadingElement)
      this.isInit = true
      clearTimeout(this.preloadTimer)

      if (loadingElement) {
        this.debug.system("‹‹‹ KILL THE LOADER ›››")
        this.$nextTick(() => {
          loadingElement.classList.add("preload-loading-screen--disabled")
        })
      } else {
        throw new Error("loadingElement missing")
      }
    },

    initRouteControl() {
      let router = this.$router
      window.changeRoute = function(to) {
        return router.push({ path: `${to}` })
      }
    }
  },

  async created() {
    // INIT RESIZE LISTENER
    ScrollLock.init(this.$store)

    this.PERSIST_PARAMS()
    window.addEventListener("resize", this.onResize)
    window.addEventListener("scroll", this.onScroll)

    await BleachAPI.initAPI(this.$store)
    this.$store.dispatch("content/INIT_CONTENT")
    this.initInputListeners()
    // this.VALIDATE_CART()
  },

  destroyed() {
    window.removeEventListener("resize", this.onResize)
    window.addEventListener("scroll", this.onScroll)
  },

  mounted() {
    this.onResize()
  },

  watch: {
    getShouldHideLoader: {
      handler: function(val) {
        this.debug.log("WATCHER - getShouldHideLoader", val)
        if (val) {
          this.hideLoadingScreen()
          ExternalLoader.loadExternals()
          this.$store.dispatch("locale/GET_GEODATA")
        }
      },
      immediate: true
    },

    routeTransitionState: {
      handler: function(val) {
        this.debug.system("ROUTE TRANSITION STATE", val)
        if (!val && this.GET_IS_CORE_CONTENT_READY && !this.isInit) {
          this.preloadTimer = setTimeout(() => {
            this.isPreloadTimeout = true
            throw new Error("TIMEOUT on preloader", {
              currentPageImageManifest: this.currentPageImageManifest
            })
          }, 10000)
        }
      },
      immediate: true
    },

    GET_VIEWPORT_IMAGES_ARE_LOADED: {
      handler: function(newVal) {
        this.debug.log("GET_VIEWPORT_IMAGES_ARE_LOADED", newVal)
        if (newVal) {
          this.debug.system("÷÷÷÷ VIEWPORT IMAGES LOADED", newVal)
        }
      }
    },

    showLocaleSuggestionModal(newVal) {
      if (newVal) {
        this.$modal_container
          .launch(StoreLocaleModal, {
            props: {
              context: this.queueGeoFetch ? "geo" : "pref"
            },
            priority: true
          })
          .catch(() => {
            this.$store.dispatch("cart/GET_CART_BY_ID")
            this.$store.dispatch("locale/REFUSE_CHANGE")
          })
      }
    },

    modalQueueEnabled(newVal) {
      if (newVal) {
        // TODO: If we end up having to enqueue a series of modals when the site first loads (terrible UX, but still) then this will need some rethinking
        if (this.getHasDiscountQuery) {
          // if(this.getHasValidatedDiscountQuery){
          this.$modal_container.launch(DiscountModal)
          // } else {

          // }
        }
      }
    },

    GET_IS_CORE_CONTENT_READY: {
      handler(newVal) {
        this.debug.system("GET_IS_CORE_CONTENT_READY", newVal)
        if (newVal) {
          // Fetch any session based cart state, either from /login or abandon cart email
          this.$store.dispatch("cart/GET_CART_BY_ID", this.$route.query.cartId)
          if (this.$route.query.openCart) {
            this.$store.dispatch("cart/OPEN_CART")
          }
        }
      },
      immediate: true
    },

    // hasFullContent(newVal) {
    //   if (newVal) this.$store.dispatch("locale/GET_GEODATA")
    // },

    // pageLoadIdle: {
    //   immediate: true,
    //   handler: function(newVal) {
    //     if (newVal) {
    //       this.debug.system("PAGE LOAD IDLE - App loading externals")
    //       ExternalLoader.loadExternals()
    //     }
    //   }
    // },

    GET_SHOW_NEWSLETTER(newVal) {
      if (newVal) {
        this.$modal_container
          .launch(NewsletterModal, { delay: 5000 })
          .catch(() => {
            this.SET_MARKETING_DECLINED(true)
          })
      }
    }
  }
}
</script>

<style lang="scss">
.bleach-site {
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &__store {
    width: 100%;
    padding-top: $nav-height-default;
    margin: 0 auto;
    min-height: 100%;

    @include media-breakpoint-up(md) {
      padding-top: ($nav-height-md);
    }

    @include media-breakpoint-up(lg) {
      padding-top: ($nav-height-lg + $menu-height-lg);
    }

    @include media-breakpoint-up(xl) {
      padding-top: ($nav-height-xl + $menu-height-lg);
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  position: absolute;
  width: 100vw;
  top: 0;
  left: 0;
  transition: opacity 1s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
